import React from 'react'
import styled from 'styled-components'
import { Callout, CALLOUT_INTENT } from 'components/Callout'
import Icon from 'components/Icon'
import { LinkWithText } from 'components/Link'
import { Text } from 'components/Typography'
import {
  HEADER_HEIGHT_DESKTOP,
  HEADER_HEIGHT_MOBILE_PARTIAL,
} from 'constants/common'
import { FONT_WEIGHT, mq, Z_INDEX } from 'Theme'

const StickyWrapper = styled.div`
  position: sticky;
  top: ${HEADER_HEIGHT_MOBILE_PARTIAL};
  z-index: ${Z_INDEX.NAVBAR};
  ${mq.from.TABLET`
    top: ${HEADER_HEIGHT_DESKTOP};
  `}
`
interface PreviewCalloutProps {
  previewOption: string
  backButtonHref: string
}

export const PreviewCallout = ({
  previewOption,
  backButtonHref,
}: PreviewCalloutProps) => (
  <StickyWrapper>
    <Callout
      icon={Icon.Visible}
      intent={CALLOUT_INTENT.WARNING}
      title={`Preview Mode. This ${previewOption}
       is not publicly visible.`}
    >
      <Text>
        Go back to{' '}
        <LinkWithText href={backButtonHref} fontWeight={FONT_WEIGHT.MEDIUM}>
          Admin dashboard.
        </LinkWithText>
      </Text>
    </Callout>
  </StickyWrapper>
)
