import get from 'lodash/get'
import { formatPageTitleWithDash } from 'utils/formatTitle'

export const DESCRIPTION_MAX_LENGTH = 200

export const getSeoPropsForCmsPage = (
  page: any,
  {
    fallbackDescription,
    fallbackMetaTitle,
  }: { fallbackDescription?: string; fallbackMetaTitle?: string } = {
    fallbackDescription: '',
    fallbackMetaTitle: '',
  }
) => {
  const cmsPage = get(page, 'cmsPage')

  // EXPERT-198: Fixing OpenGraph redirect issue
  let urlPath = get(cmsPage, 'href')
  urlPath = urlPath === '/home' ? '' : urlPath

  return {
    title: formatPageTitleWithDash(get(cmsPage, 'title')),
    metaTitle:
      get(cmsPage, 'metaTitle') ||
      (fallbackMetaTitle ?? formatPageTitleWithDash(get(cmsPage, 'title'))),
    keywords: get(cmsPage, 'keywords'),
    description:
      get(cmsPage, 'metaDescription')?.length > 0
        ? get(cmsPage, 'metaDescription')
        : fallbackDescription,
    author: get(page, 'author'),
    previewAttachmentUrl: get(page, ['previewAttachment', 'url']),
    urlPath,
  }
}
