import { type NextPageWithLayout } from 'next'
import { type ReactNode } from 'react'
import React from 'react'
import sanitizeHtml from 'sanitize-html'
import { ALLOWED_WIDGET_TYPES } from '@admin/routes/Cms/components/Widgets'
import {
  PreviewCallout,
  PREVIEW_PAGE_ENUM,
} from '@public/components/PreviewCallout'
import { getPublicLayout } from '@public/components/PublicLayout'
import { Error } from 'components/Error'
import { LOADER_SIZE } from 'components/Loader'
import { PureSSRIndicator } from 'components/PureSSRIndicator'
import { Seo } from 'components/Seo'
import { WidgetsWrapper } from 'components/Widgets'
import { WrappedLoader, PAGE_MIN_HEIGHT } from 'components/WrappedLoader'
import { ROUTE } from 'constants/routes'
import { SPACE, WIDGET_MAX_WIDTH } from 'Theme'
import { truncateStringWithEllipsisInTheEnd } from 'utils/truncateStringWithEllipsis'
import {
  DESCRIPTION_MAX_LENGTH,
  getSeoPropsForCmsPage,
} from '../getSeoPropsForCmsPage'
import { useCmsArticlePageBySlug } from './hooks'
import { prepareGetStaticPropsForArticlePage } from './prepareGetStaticPropsForArticlePage'

interface ArticlePageProps {
  isPreview?: boolean
  slug: string
}

export const ArticlePage: NextPageWithLayout<ArticlePageProps> = ({
  slug,
  isPreview,
}) => {
  const { pageBySlug, isLoading, widgets } = useCmsArticlePageBySlug({
    slug,
    isPreview,
  })
  if (isLoading) {
    return (
      <WrappedLoader
        loaderSize={LOADER_SIZE.XXXL}
        minHeight={PAGE_MIN_HEIGHT}
      />
    )
  }

  if (pageBySlug == null) {
    return <Error />
  }

  return (
    <>
      <Seo
        {...getSeoPropsForCmsPage(pageBySlug, {
          fallbackDescription: truncateStringWithEllipsisInTheEnd(
            sanitizeHtml(
              pageBySlug.cmsPage.widgets.find(
                // finding first rich text widget (that should always be first article of blog actually)
                (cmsWidget) =>
                  cmsWidget.content.__typename ===
                  ALLOWED_WIDGET_TYPES.RICH_TEXT
              )?.content.richText,
              { allowedTags: [] }
            ) || '',
            DESCRIPTION_MAX_LENGTH
          ),
        })}
      />
      {isPreview && (
        <PreviewCallout
          previewOption={PREVIEW_PAGE_ENUM.BLOG_PREVIEW}
          backButtonHref={ROUTE.ADMIN_CMS_ARTICLE_PAGE({ id: slug })}
        />
      )}
      <WidgetsWrapper
        widgets={widgets}
        slug={slug}
        mb={{ TABLET: SPACE.PX_40, MOBILE: SPACE.PX_5 }}
        maxWidgetWidth={WIDGET_MAX_WIDTH.ARTICLE}
      />
      <PureSSRIndicator />
    </>
  )
}

export function getStaticPaths() {
  return {
    paths: [],
    fallback: 'blocking',
  }
}

export function getStaticProps(context) {
  return prepareGetStaticPropsForArticlePage(context, {
    slug: context.params.slug,
    route: ROUTE.BLOG_DETAIL({ slug: context.params.slug }),
  })
}

const PageLayout = (page: ReactNode) =>
  getPublicLayout(page, { isFullWidth: true, isWithBreadcrumbs: true })

ArticlePage.getLayout = PageLayout
