import React from 'react'
import { DATA_TEST_ID } from 'shared-constants/build/testIds'
import { Box } from 'components/Layout'

export const PureSSRIndicator = () => (
  <noscript>
    <Box
      data-test-id={DATA_TEST_ID.SSR_INDICATOR}
      data-snapshot-hidden
      aria-hidden
    />
  </noscript>
)
