import { gql } from '@apollo/client'
import {
  CmsArticlesArticleFieldsFragmentDoc as CMS_ARTICLES_ARTICLE_FIELDS_FRAGMENT,
  CmsWidgetFieldsFragmentDoc as CMS_WIDGET_FIELDS_FRAGMENT,
} from 'types/graphql-generated'

export const CMS_ARTICLE_PAGE_BY_SLUG_QUERY = gql`
  query cmsArticlePageBySlug(
    $slug: String!
    $widgetsDefaultInputs: WidgetsDefaultInputs
  ) {
    cmsArticlePageBySlug(slug: $slug) {
      author
      previewAttachment {
        url
        alt
      }
      cmsPage {
        title
        metaDescription
        metaTitle
        keywords
        href
        widgets(widgetsDefaultInputs: $widgetsDefaultInputs)
          @connection(key: $slug) {
          ...CMSWidgetFields
        }
      }
    }
  }
  ${CMS_WIDGET_FIELDS_FRAGMENT}
  ${CMS_ARTICLES_ARTICLE_FIELDS_FRAGMENT}
`

export const CMS_ARTICLE_PAGE_PREVIEW = gql`
  query cmsArticlePagePreview(
    $slug: String!
    $widgetsDefaultInputs: WidgetsDefaultInputs
  ) {
    cmsArticlePagePreview(slug: $slug) {
      id
      author
      previewAttachment {
        url
        alt
      }
      cmsPage {
        id
        title
        metaDescription
        metaTitle
        keywords
        href
        widgets(widgetsDefaultInputs: $widgetsDefaultInputs)
          @connection(key: $slug) {
          ...CMSWidgetFields
        }
      }
    }
  }
  ${CMS_WIDGET_FIELDS_FRAGMENT}
  ${CMS_ARTICLES_ARTICLE_FIELDS_FRAGMENT}
`

export const CMS_SHOWROOM_PAGE_BY_SLUG_QUERY = gql`
  query cmsShowroomPageBySlug(
    $slug: String!
    $widgetsDefaultInputs: WidgetsDefaultInputs
  ) {
    cmsShowroomPageBySlug(slug: $slug) {
      cmsPage {
        title
        metaDescription
        metaTitle
        keywords
        href
        widgets(widgetsDefaultInputs: $widgetsDefaultInputs)
          @connection(key: $slug) {
          ...CMSWidgetFields
        }
      }
    }
  }
  ${CMS_WIDGET_FIELDS_FRAGMENT}
`

export const CMS_SHOWROOM_PAGE_PREVIEW = gql`
  query cmsShowroomPagePreview(
    $slug: String!
    $widgetsDefaultInputs: WidgetsDefaultInputs
  ) {
    cmsShowroomPagePreview(slug: $slug) {
      cmsPage {
        title
        metaDescription
        metaTitle
        keywords
        href
        widgets(widgetsDefaultInputs: $widgetsDefaultInputs)
          @connection(key: $slug) {
          ...CMSWidgetFields
        }
      }
    }
  }
  ${CMS_WIDGET_FIELDS_FRAGMENT}
`
