import { useQuery } from '@apollo/client'
import { get } from 'lodash'
import {
  ARTICLES_WIDGET_LIMIT,
  COLLECTION_WIDGET_LIMIT,
  COLLECTION_SORTER_FIELDS,
  SORTER_DIRECTION,
  PRODUCT_STATUSES,
  PRODUCT_VARIANT_STATUSES,
} from 'constants/common'
import {
  CMS_ARTICLE_PAGE_BY_SLUG_QUERY,
  CMS_ARTICLE_PAGE_PREVIEW,
} from 'constants/queries'
import { useUserAuth } from 'providers/userAuth'
import { useMedia } from 'useMedia'

interface UseCmsArticlePageBySlugProps {
  isPreview?: boolean
  slug: string
}

export const useCmsArticlePageBySlug = ({
  slug,
  isPreview = false,
}: UseCmsArticlePageBySlugProps) => {
  const media = useMedia()
  const { isAdminSignedIn, isPageLoading } = useUserAuth()
  const [articlesWidgetPageSize, multiCollectionWidgetPageSize] = media.MOBILE
    ? [ARTICLES_WIDGET_LIMIT.MOBILE, COLLECTION_WIDGET_LIMIT.MOBILE]
    : [ARTICLES_WIDGET_LIMIT.DESKTOP, COLLECTION_WIDGET_LIMIT.DESKTOP]

  const { data, loading: isLoading } = useQuery(
    isPreview ? CMS_ARTICLE_PAGE_PREVIEW : CMS_ARTICLE_PAGE_BY_SLUG_QUERY,
    {
      fetchPolicy: 'cache-first', // everything should be in cache, so we can use this policy
      skip: isPreview && !isAdminSignedIn,
      variables: {
        slug,
        widgetsDefaultInputs: {
          cmsExpertsInput: {
            page: 1,
          },
          cmsArticlesInput: {
            page: 1,
            limit: articlesWidgetPageSize,
          },
          cmsMultiCollectionProductsInput: {
            pagination: {
              page: 1,
              limit: multiCollectionWidgetPageSize,
            },
            sorter: {
              field: COLLECTION_SORTER_FIELDS.POSITION_INDEX_WEIGHT,
              direction: SORTER_DIRECTION.ASC,
            },
            filter: {
              statuses: [PRODUCT_STATUSES.PUBLISHED],
              variantStatuses: [PRODUCT_VARIANT_STATUSES.PUBLISHED],
            },
          },
        },
      },
    }
  )

  const pageBySlug = isPreview
    ? get(data, ['cmsArticlePagePreview'])
    : get(data, ['cmsArticlePageBySlug'])
  const widgets = get(pageBySlug, ['cmsPage', 'widgets'])
  return {
    pageBySlug,
    widgets,
    isLoading: (isPreview && isPageLoading) || isLoading,
  }
}
