import { datadogRum } from '@datadog/browser-rum'
import { REVALIDATE_INTERVAL_IN_SECONDS } from 'constants/common'
import { GetBreadcrumbsByPathDocument } from 'types/graphql-generated'
import { initializeApollo } from 'utils/apolloClient'

export const prepareBreadcrumbsProps = async (
  apolloClient: any,
  route: string
) => {
  try {
    const { data: breadcrumbsData } = await apolloClient.query({
      query: GetBreadcrumbsByPathDocument,
      variables: {
        path: route,
      },
    })

    const breadcrumbs = breadcrumbsData?.getBreadcrumbsByPath

    if (breadcrumbs?.length === 1 && breadcrumbs[0].disabled === true) {
      return []
    }

    return breadcrumbs
  } catch (error) {
    datadogRum.addError(error)
    return []
  }
}

export const prepareGetStaticBreadcrumbsProps = async (route: string) => {
  try {
    const apolloClient = initializeApollo()
    const breadcrumbs = await prepareBreadcrumbsProps(apolloClient, route)

    return {
      props: {
        breadcrumbs,
        // TODO fix initialApolloState after getDataFromTree implementation #1545
        initialApolloState: apolloClient.cache.extract(),
      },
      revalidate: REVALIDATE_INTERVAL_IN_SECONDS,
    }
  } catch (error) {
    datadogRum.addError(error)

    return {
      notFound: true,
      props: { breadcrumbs: [] },
      revalidate: REVALIDATE_INTERVAL_IN_SECONDS,
    }
  }
}
