import React from 'react'
import { getPublicLayout } from '@public/components/PublicLayout'
import { Error } from 'components/Error'
import { LOADER_SIZE } from 'components/Loader'
import { PureSSRIndicator } from 'components/PureSSRIndicator'
import { Seo } from 'components/Seo'
import { WidgetsWrapper } from 'components/Widgets'
import { PAGE_MIN_HEIGHT, WrappedLoader } from 'components/WrappedLoader'
import { HOME_PAGE_SLUG } from 'constants/common'
import { withGA4Provider } from 'hoc/withGoogleAnalytics'
import { GA4_ITEM_LIST_NAME, GA4_PAGE_AREAS } from 'providers/googleAnalytics'
import {
  useCmsArticlePageBySlug,
  prepareGetStaticPropsForArticlePage,
} from './CmsPage/ArticlePage'
import { getSeoPropsForCmsPage } from './CmsPage/getSeoPropsForCmsPage'

const IndexPage = () => {
  const { pageBySlug, isLoading, widgets } = useCmsArticlePageBySlug({
    slug: HOME_PAGE_SLUG,
  })

  if (isLoading) {
    return (
      <WrappedLoader
        loaderSize={LOADER_SIZE.XXXL}
        minHeight={PAGE_MIN_HEIGHT}
      />
    )
  }

  if (pageBySlug == null) {
    return <Error />
  }

  return (
    <>
      <Seo
        {...getSeoPropsForCmsPage(pageBySlug, {
          fallbackMetaTitle:
            'The Expert: Online Interior Design Consultations & Furniture and Décor',
          fallbackDescription:
            "Book online consultations with the world's top interior designers and shop the best vintage and contemporary furniture, decor, lighting, rugs, and more handpicked by our Experts.",
        })}
      />
      <WidgetsWrapper widgets={widgets} slug={HOME_PAGE_SLUG} />
      <PureSSRIndicator />
    </>
  )
}

export const Index = withGA4Provider({
  pagearea: GA4_PAGE_AREAS.HP,
  item_list_name: GA4_ITEM_LIST_NAME.HOME_PAGE,
})(IndexPage)

export function getStaticProps(context) {
  return prepareGetStaticPropsForArticlePage(context, { slug: HOME_PAGE_SLUG })
}

const PageLayout = (page) => getPublicLayout(page, { isFullWidth: true })

Index.getLayout = PageLayout

export default Index
