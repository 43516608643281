import { datadogRum } from '@datadog/browser-rum'
import { get } from 'lodash'
import { REVALIDATE_INTERVAL_IN_SECONDS } from 'constants/common'
import { PublishedNavigationGroupsDocument } from 'types/graphql-generated'
import { initializeApollo } from 'utils/apolloClient'

export const prepareNavigationProps = async (apolloClient) => {
  try {
    const { data: navigationData } = await apolloClient.query({
      query: PublishedNavigationGroupsDocument,
    })

    const navigation = get(navigationData, 'publishedNavigationGroups', []).map(
      (navigationGroup) =>
        get(navigationGroup, ['publishedNavigation', 'rootItem'])
    )

    return navigation
  } catch (error) {
    datadogRum.addError(error)
    return []
  }
}

export const prepareGetStaticNavigationProps = async () => {
  try {
    const apolloClient = initializeApollo()
    const navigation = await prepareNavigationProps(apolloClient)

    return {
      props: {
        navigation,
        // TODO fix initialApolloState after getDataFromTree implementation #1545
        initialApolloState: apolloClient.cache.extract(),
      },
      revalidate: REVALIDATE_INTERVAL_IN_SECONDS,
    }
  } catch (error) {
    datadogRum.addError(error)
    return {
      notFound: true,
      props: { navigation: [] },
      revalidate: REVALIDATE_INTERVAL_IN_SECONDS,
    }
  }
}
