import { type GetStaticPropsContext } from 'next'
import { CMS_ARTICLE_PAGE_BY_SLUG_QUERY } from 'constants/queries'
import { prepareGetStaticProps } from '../prepareGetStaticProps'

export function prepareGetStaticPropsForArticlePage(
  context: GetStaticPropsContext,
  {
    slug,
    route,
  }: {
    slug: string
    route?: string
  }
) {
  return prepareGetStaticProps(context, {
    slug,
    query: CMS_ARTICLE_PAGE_BY_SLUG_QUERY,
    route,
  })
}
